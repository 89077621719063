<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 0.6rem;">名称：</span>
					<el-input v-model="searchinput" placeholder="请输入名称" style="width: 2rem;"></el-input>
				</div>
				<div class="search_item">
					<el-button type="primary" @click="search">搜索</el-button>
				</div>
			</div>
			<div class="box_cont">
				<div class="btn_list">
				</div>
				<div style="height: 76vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" :showbm="true"
						:czwidth="120" @getdata="gettable" @changswitch="changswitch">
						<template slot="btns" slot-scope="{ scope }">
							<el-button @click="showeidt(scope.row)" type="success" size="mini">详情</el-button>
							<!-- <el-button v-show="scope.row.apply_status==0" @click="pass(scope.row)" type="primary" size="mini">通过</el-button>
							<el-button v-show="scope.row.apply_status==0" @click="bohui(scope.row)" type="danger" size="mini">驳回</el-button> -->
						</template>
					</MyTable>
				</div>
			</div>
		</div>
		
		<detail ref="mydetail"></detail>
	</div>
</template>

<script>
	import detail from './component/detail.vue';
	export default {
		components:{
			detail
		},
		data() {
			return {
				searchinput: '',
				tableHeader: [{
						prop: 'id',
						name: '序',
						width: '50'
					},
					{
						prop: 'realname',
						name: '用户名',
						width: ''
					},
					{
						prop: 'mobile',
						name: '手机号',
						width: ''
					},
					{
						prop: 'gender',
						name: '性别',
						width: ''
					},
					{
						prop: 'apply_status',
						name: '状态',
						width: '',
						type:'status_lztb'
					},
					{
						prop: 'createtime',
						name: '提交时间',
						width: ''
					}
				],
				tableData: [],
				showcode: false,
				qr_url: '',
				imageLogo: require("@/assets/logo.png"),
			}
		},
		mounted() {
			this.gettable()
		},
		methods: {
			editerow(row) {
				this.$router.push({
					path: '/system/activity_edit',
					query: {
						id: row.id
					}
				})
			},
			pass(row) {
				this.$confirm('请确认是否审核通过?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$post({
						url: '/api/user_resumption/pass',
						params: {
							id: row.id
						}
					}).then((res) => {
						this.$message.success('操作成功')
						this.gettable()
					})
				}).catch(() => {

				})
			},
			bohui(row) {
				this.$prompt('请输入驳回原因', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputPattern: /\S/,
					inputErrorMessage: '请输入驳回理由'
				}).then(({
					value
				}) => {
					this.$post({
						url: '/api/user_resumption/refund',
						params: {
							id: row.id,
							refund_text:value
						}
					}).then((res) => {
						this.$message.success('操作成功')
						this.gettable()
					})
				}).catch(() => {
					
				});
			},
			showeidt(row) {
				let objstr = JSON.parse(JSON.stringify(row))
				this.$refs.mydetail.dialogVisible = true
				objstr.diaoyan = JSON.parse(objstr.diaoyan)
				objstr.form_summarize = JSON.parse(objstr.form_summarize)
				objstr.honor_punish_content = JSON.parse(objstr.honor_punish_content)
				objstr.meeting_speak = JSON.parse(objstr.meeting_speak)
				objstr.other = JSON.parse(objstr.other)
				objstr.shicha = JSON.parse(objstr.shicha)
				console.log(objstr)
				this.$refs.mydetail.form = objstr
			},
			changswitch(row) {
				this.$post({
					url: '/api/room/edit',
					params: row
				}).then((res) => {
					this.$message({
						type: 'success',
						message: '操作成功!'
					})
					this.gettable()
				})
			},
			search() {
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable() {
				this.$post({
					url: '/api/user_performance_form/performance_from_list',
					params: {
						apply_status:"",
						is_review:'',
						name: this.searchinput,
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize
					}
				}).then((res) => {
					this.tableData = res.list
					this.$refs.zjlist.total = res.count
				})
			},
			addnew() {
				this.$router.push('/system/activity_add')
			},
			showdate(row) {
				this.$refs.myroomdate.dialogVisible = true
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import 'index.scss';
</style>